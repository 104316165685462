import React, { useEffect } from "react";

const Youtube = (props: YoutubeProps) => {
  let player; 
  useEffect(() => {
    const tag = document.createElement('script');
    tag.id = 'video-player-iframe';
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    (window as any).onYouTubeIframeAPIReady = function onYouTubeIframeAPIReady() {
      player = new (window as any).YT.Player('youtube-video');
    }
  }, []);

  return <iframe id="youtube-video"
        width="480" height="270"
        src={`https://www.youtube.com/embed/${props.youtubeVideoId}?enablejsapi=1`}
      ></iframe>;
};

interface YoutubeProps {
  youtubeVideoId: string;
}

export default Youtube;
