import { graphql } from "gatsby";
import React from "react";
import "bootstrap/scss/bootstrap.scss";
import NavBar from "../components/nav/NavBar";
import Footer from "../components/footer/Footer";
import {
  RenderRichTextData,
  ContentfulRichTextGatsbyReference,
} from "gatsby-source-contentful/rich-text";
import Article from "../components/article/Article";
import { SEO } from "../components/seo/seo";

const ContentfulPage = (props: ArticleProps): React.ReactElement<any> => {
  const { contentfulArticle } = props.data;
  return (
    <>
      <NavBar />
      <Article {...contentfulArticle} />
      <Footer />
    </>
  );
};

interface ArticleProps {
  data: {
    contentfulArticle: {
      pagePath: string;
      headline: string;
      subheadline?: RenderRichTextData<ContentfulRichTextGatsbyReference>;
      youtubeVideoId?: string;
      hero: any;
      body: RenderRichTextData<ContentfulRichTextGatsbyReference>;
      articleType: 'sermon' | 'lecture';
      markdownBody: { markdownBody: string };
      articleResources: { articleResources: string };
    }
  }
}

export const query = graphql`
  query ($id: String) {
    contentfulArticle(id: { eq: $id }) {
      title
      pagePath
      headline
      articleType
      subheadline {
        raw
      }
      body {
        raw
      }
      youtubeVideoId
      markdownBody {
        markdownBody
      }
      articleResources {
        articleResources
      }
      hero {
        ... on ContentfulHero {
          headline {
            raw
          }
          subheadline {
            raw
          }
          backgroundImage {
            gatsbyImageData(height: 400)
          }
        }
      }
    }
  }
`;

export default ContentfulPage;

export const Head = ({ data }) => {
  const { contentfulArticle } = data
  const pageMetadata = {
    title: `Canons Regular of the New Jerusalem | ${contentfulArticle.title}`,
    description: contentfulArticle.headline,
    keywords: ['Canons Regular of the New Jerusalem', 'sermon', contentfulArticle.title],
    canonicalUrl: `https://www.canonsregular.com${contentfulArticle.pagePath}`
  }
  return <SEO metadata={pageMetadata} />
}
