import {
  renderRichText,
  RenderRichTextData,
  ContentfulRichTextGatsbyReference,
} from "gatsby-source-contentful/rich-text";
import { marked } from "marked";
import React from "react";
import Hero from "../hero/Hero";
import Youtube from "../youtube/Youtube";

import "./Article.scss";

const Article = (props: ArticleProps) => {
  return (
    <div className="article-wrapper">
      {props.hero && <Hero {...props.hero} />}
      <div className="article-content container">
        <div className="row">
          <div className="col">
            <h2 className="headline">{props.headline}</h2>
            <div className="subheadline">
              {props.subheadline && renderRichText(props.subheadline)}
            </div>
            <hr />
            <div className="youtube-container">
              {props.youtubeVideoId && (
                <Youtube youtubeVideoId={props.youtubeVideoId} />
              )}
            </div>
            {props.body && renderRichText(props.body)}
            {props.markdownBody && (
              <div
                dangerouslySetInnerHTML={{
                  __html: marked(props.markdownBody.markdownBody),
                }}
              ></div>
            )}
            {props.articleResources && (
              <div className="mt-3">
                <div className="h6">
                  Additional Resources
                </div>
                <div
                dangerouslySetInnerHTML={{
                  __html: marked(props.articleResources.articleResources),
                }}
              ></div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export interface ArticleProps {
  headline: string;
  subheadline?: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  youtubeVideoId?: string;
  hero: any;
  body: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  articleType: "sermon" | "lecture";
  pagePath: string;
  markdownBody: { markdownBody: string };
  articleResources: { articleResources: string };
}

export default Article;
